<template>
    <aside :class="['hackathons-sidebar', { slim }]">
        <div class="scroll-wrap">
            <router-link
                v-for="hackathon in hackathons"
                :key="hackathon.id"
                :class="['hackathon', { 'active' : hackathonId === hackathon.id }]"
                :to="{ name: 'hackathon', params: { id: hackathon.id } }"
                :title="hackathon.name"
            >
                <div v-if="isActiveHackathon(hackathon)" class="ribbon">
                    <span>Join now!</span>
                </div>

                <div
                    class="hackathon-image"
                    :style="`background-image: url(${hackathon.thumbnail || hackathon.image})`"
                />

                <span class="hackathon-name">{{ hackathon.name }}</span>
            </router-link>
        </div>
    </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        slim: Boolean,
    },

    computed: {
        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
            project: ({ projects }) => projects.project,
            hackathon: ({ hackathons }) => hackathons.hackathon,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        hackathonId() {
            if (this.$route.name === 'project') return this.project.hackathonId;
            if (this.$route.name === 'hackathon') return this.hackathon.id;

            return this.$route.params.id;
        },
    },

    methods: {
        isActiveHackathon(hackathon) {
            return this.activeHackathon?.id === hackathon.id;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.hackathons-sidebar {
    overflow: hidden;
    width: 150px;
    padding-top: $spacing-200;

    @media($small) {
        width: 120px;
    }

    @media($extra-small) {
        width: 80px;
    }

    &.slim {
        padding: 1px;
        width: 60px;

        .hackathon {
            padding: $spacing-050;
            margin-bottom: $spacing-050;
        }

        .hackathon-image {
            height: 40px;
        }

        .hackathon-name {
            display: none;
        }
    }
}

.scroll-wrap {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;

    scrollbar-width: none;
    scrollbar-color: $color-gray $color-gray-900;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: $color-gray-900;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-gray-700;
        border-radius: 5px;
        border: 4px solid $color-gray-900;
    }
}

.hackathon {
    width: 100%;
    height: auto;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease;
    padding: $spacing-100;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-bottom: $spacing-050;
    color: $color-gray-100;
    text-decoration: none;

    @media($small) {
        padding: $spacing-050;
    }

    &.active {
        background: $color-gray-050;
        transition: background-color 300ms ease;
        color: $color-ink-800;
        position: sticky;
        top: 0;
        bottom: $spacing-200;
        z-index: 2;

        &:hover {
            background: $color-gray-050;
            color: $color-ink-900;
        }
    }

    &:hover {
        background-color: $color-paper-500;
    }
}

.hackathon-image {
    width: 100%;
    height: 120px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: $border-radius;
    height: 98px;

    @media($small) {
        height: 94px;
        border-radius: calc($border-radius / 2);
    }

    @media($extra-small) {
        height: 50px;
    }
}

.hackathon-name {
    font-weight: bold;
    width: 100%;
    padding-top: $spacing-100;

    @media($small) {
        display: none;
    }
}

.ribbon {
    @media($extra-small) {
        display: none;
    }

    @media($small) {
        top: 0;
    }

    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
        font-size: 10px;
        font-weight: bold;
        color: $color-green-050;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        background: $color-green-600;
        background: linear-gradient($color-green-700 0%, $color-green-600 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px; right: -21px;

        &::before {
            content: "";
            position: absolute; left: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid $color-green-600;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $color-green-600;
        }

        &::after {
            content: "";
            position: absolute; right: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid transparent;
            border-right: 3px solid $color-green-600;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $color-green-600;
        }
    }
}
</style>
